<template>
    <div class="Contactus">
        <!-- <div class="Cont_back">
            <img src="../../assets/banner.png" alt="">
        </div> -->
        <div class="main">
            <div class="content-box">
                <div class="content clearfix">
                    <div class="right-content">
                        <p class="content_p1">
                            <strong>
                                <span style="font-family: 宋体;font-size: 16px">素材库 V1.6.0 服务使用协议</span>
                            </strong>
                        </p>
                        <p class="content_p1" style="height:30px">
                            <strong>
                                <span style="font-family: 宋体;font-size: 16px">&nbsp;</span>
                            </strong>
                        </p>
                        <p class="content_p1">
                            <strong>
                                <span style="font-family: 宋体;font-size: 13px">更新时间：2021年3月31日</span>
                            </strong>
                        </p>
                        <p class="content_p1" style="height:30px">
                            <strong>
                                <span style="font-family: 宋体;font-size: 16px">&nbsp;</span>
                            </strong>
                        </p>
                        <p class="content_p2">
                            <span style="font-family: 宋体;font-size:13px">
                                感谢您选择素材库（以下简称“本服务”）！本协议是您与课之一（西安）科技有限公司之间关于您使用素材库服务所订立的协议。在使用本服务前，了解我们提供的服务以及我们对您的期望是非常必要的，我们将尽可能的使用通俗易懂的语言全面的向您展现本协议的内容，我们也希望您花一些时间熟悉本协议以下条款。
                            </span>
                        </p>
                        <p class="content_p1" style="height:30px">
                            <strong>
                                <span style="font-family: 宋体;font-size: 16px">&nbsp;</span>
                            </strong>
                        </p>
                        <p class="content_p2">
                            <strong>
                                <span style="font-family: 宋体;font-size: 13px">
                                    我们在此善意地提醒您，本协议中与您的权益（可能）存在重大关系的条款，我们已采用字体加粗的方式来特别提醒您，请您留意重点查阅。
                                </span>
                            </strong>
                            <span style="font-family: 宋体;font-size:13px">
                                您应当在确认充分阅读本协议字体加粗条款，明确理解了该等字体加粗条款的前提下使用本服务。
                            </span>
                        </p>
                        <p class="content_p1" style="height:30px">
                            <strong>
                                <span style="font-family: 宋体;font-size: 16px">&nbsp;</span>
                            </strong>
                        </p>
                        <p class="content_p2">
                            <span style="font-family: 宋体;font-size:13px">
                                如果您对本协议及本服务有任何问题、疑惑，请随时联系我们，我们为您提供了多种反馈渠道，希望为您提供满意的解决方案：
                            </span>
                        </p>
                        <p class="content_p3">
                            <span style="font-family: Wingdings;font-size: 13px">l&nbsp;</span>
                            <span style="font-family: 宋体;font-size: 13px"><span style="font-family:宋体">人工客服通道：您可以在工作日的工作时间（</span>8:30-17:30）拨打我们的客服热线：029-88892465；</span>
                        </p>
                        <p class="content_p3">
                            <span style="font-family: Wingdings;font-size: 13px">l&nbsp;</span>
                            <span style="font-family: 宋体;font-size: 13px"><span style="font-family:宋体">专设的邮件通道：我们专门设立了</span>service@ihago.com邮箱，您可以通过发送电子邮件至该邮箱与我们联系；</span>
                        </p>
                        <p class="content_p2">
                            <span style="font-family: 宋体;font-size:13px">
                               本协议将帮助您了解以下内容：
                            </span>
                        </p>
                        <p class="content_p4">
                            <span style="font-family: Wingdings;font-size: 13px">l&nbsp;</span>
                            <span style="font-family: 宋体;font-size: 13px"><span style="font-family:宋体">服务费用</span></span>
                        </p>
                        <p class="content_p4">
                            <span style="font-family: Wingdings;font-size: 13px">l&nbsp;</span>
                            <span style="font-family: 宋体;font-size: 13px"><span style="font-family:宋体">本服务的变更</span></span>
                        </p>
                        <p class="content_p4">
                            <span style="font-family: Wingdings;font-size: 13px">l&nbsp;</span>
                            <span style="font-family: 宋体;font-size: 13px"><span style="font-family:宋体">本服务的限制</span></span>
                        </p>
                        <p class="content_p4">
                            <span style="font-family: Wingdings;font-size: 13px">l&nbsp;</span>
                            <span style="font-family: 宋体;font-size: 13px"><span style="font-family:宋体">本服务授权使用限制</span></span>
                        </p>
                        <p class="content_p4">
                            <span style="font-family: Wingdings;font-size: 13px">l&nbsp;</span>
                            <span style="font-family: 宋体;font-size: 13px"><span style="font-family:宋体">免责声明及有限保证</span></span>
                        </p>
                        <p class="content_p4">
                            <span style="font-family: Wingdings;font-size: 13px">l&nbsp;</span>
                            <span style="font-family: 宋体;font-size: 13px"><span style="font-family:宋体">用户行为</span></span>
                        </p>
                        <p class="content_p4">
                            <span style="font-family: Wingdings;font-size: 13px">l&nbsp;</span>
                            <span style="font-family: 宋体;font-size: 13px"><span style="font-family:宋体">违约及处理</span></span>
                        </p>
                        <p class="content_p4">
                            <span style="font-family: Wingdings;font-size: 13px">l&nbsp;</span>
                            <span style="font-family: 宋体;font-size: 13px"><span style="font-family:宋体">法律管辖</span></span>
                        </p>
                        <p class="content_p4">
                            <span style="font-family: Wingdings;font-size: 13px">l&nbsp;</span>
                            <span style="font-family: 宋体;font-size: 13px"><span style="font-family:宋体">其他</span></span>
                        </p>
                        <!-- 服务费用 -->
                        <p class="content_p5">
                            <span style="font-family: 宋体;font-weight: bold;font-size: 19px">（一）&nbsp;</span>
                            <strong><span style="font-family: 宋体;font-size: 19px"><span style="font-family:宋体">服务费用</span></span></strong>
                        </p>
                        <p class="content_p4" style="margin-left:21px">
                            <span style="font-family: 宋体;color: rgb(46, 46, 46);font-weight: bold;font-size: 13px;background: rgb(255, 255, 255)">1.&nbsp;</span>
                            <strong>
                                本服务包含的由我们和/或与我们有合作关系的第三方提供的具体服务可能以免费或收费的方式向您提供，针对某一项具体服务是否收费以及资费标准等均由我们和/或与我们有合作关系的第三方自行决定。
                            </strong>
                        </p>
                        <p class="content_p4" style="margin-left:21px">
                            <span style="font-family: 宋体;color: rgb(46, 46, 46);font-weight: bold;font-size: 13px;background: rgb(255, 255, 255)">2.&nbsp;</span>
                            <strong>
                                如您使用的本服务中的收费服务是由我们向您提供的，您应向我们支付对应的服务费用。
                            </strong>
                        </p>
                        <p class="content_p4" style="margin-left:21px">
                            <span style="font-family: 宋体;color: rgb(46, 46, 46);font-weight: bold;font-size: 13px;background: rgb(255, 255, 255)">3.&nbsp;</span>
                            <strong>
                                 如您使用的本服务中的收费服务是由与我们有合作关系的第三方提供的，如我们为提供收费服务的第三方代收服务费用，则您应按照我们公布的计费方式及指定的支付渠道向我们支付对应的服务费用。如我们并未为提供收费服务的第三方代收服务费用，则您应直接与提供收费服务的第三方联系，按照该第三方的有关服务费用的约定及政策，直接向提供收费服务的第三方支付相应的服务费用。您知悉并认可，因您向提供收费服务的第三方支付服务费用（包括我们为提供收费服务的第三方代收的服务费用）产生的一切争议及纠纷，均与我们无关，我们不承担任何担保及责任。同时，我们有权自行决定是否为提供收费服务的第三方代收服务费用。
                            </strong>
                        </p>
                        <!-- 本服务的变更 -->
                        <p class="content_p5">
                            <span style="font-family: 宋体;font-weight: bold;font-size: 19px">（二）&nbsp;</span>
                            <strong><span style="font-family: 宋体;font-size: 19px"><span style="font-family:宋体">本服务的变更</span></span></strong>
                        </p>
                        <p class="content_p4" style="margin-left:21px">
                            <span style="font-family: 宋体;color: rgb(46, 46, 46);font-weight: bold;font-size: 13px;background: rgb(255, 255, 255)">1.&nbsp;</span>
                            <strong>
                                 本服务项下的由我们提供的具体服务的服务内容、服务标准及服务项目数量等均以我们届时实际提供的为准；由与我们有合作关系的第三方提供的具体服务的服务内容、服务标准及服务项目数量等均以该第三方届时提供的为准，我们不对该第三方提供的具体服务的服务内容、服务标准及服务项目数量等做任何的承诺或担保。
                            </strong>
                        </p>
                        <p class="content_p4" style="margin-left:21px">
                            <span style="font-family: 宋体;color: rgb(46, 46, 46);font-weight: bold;font-size: 13px;background: rgb(255, 255, 255)">2.&nbsp;</span>
                            <span style="font-family: 宋体;font-size: 13px;">
                                素材库平台下的任何服务都有可能被关闭、替换或在未来重新开启，也有可能在后续增加新的服务内容及种类，对此我们有权自行决定。
                            </span>
                            <strong>
                                如因我们关闭或替代素材库平台下任何服务导致您或第三方遭受损失的，我们不承担任何责任，但我们将在我们认为必要的范围内积极协助您减少因此产生的损失。
                            </strong>
                        </p>
                        <!-- 本服务的限制 -->
                        <p class="content_p5">
                            <span style="font-family: 宋体;font-weight: bold;font-size: 19px">（三）&nbsp;</span>
                            <strong><span style="font-family: 宋体;font-size: 19px"><span style="font-family:宋体">本服务的限制</span></span></strong>
                        </p>
                        <p class="content_p4" style="margin-left:21px">
                            <span style="font-family: 宋体;color: rgb(46, 46, 46);font-weight: bold;font-size: 13px;background: rgb(255, 255, 255)">1.&nbsp;</span>
                            <strong>
                                 本服务仅供您自行使用，您在任何时候均无权以任何方式将本服务包含的任何部分或全部予以转让、转授权、分授权给您以外的任何一方。如本款约定与本协议其他条款约定发生冲突均以本款约定为准。
                            </strong>
                        </p>
                        <p class="content_p4" style="margin-left:21px">
                            <span style="font-family: 宋体;color: rgb(46, 46, 46);font-weight: bold;font-size: 13px;background: rgb(255, 255, 255)">2.&nbsp;</span>
                            <strong>
                                 您知悉并认可，本服务所包含的部分子服务会对您在线使用该等服务的设备数量设置上限，如超出前述设备数量的上限，我们有权冻结该账号。具体您使用本服务时所受到的有关设备类型、数量等的限制，以您届时具体使用本服务时显示的提示为准。
                            </strong>
                        </p>
                        <!-- 本服务授权使用限制 -->
                        <p class="content_p5">
                            <span style="font-family: 宋体;font-weight: bold;font-size: 19px">（四）&nbsp;</span>
                            <strong><span style="font-family: 宋体;font-size: 19px"><span style="font-family:宋体">本服务授权使用限制</span></span></strong>
                        </p>
                        <p class="content_p4" style="margin-left:21px">
                            <span style="font-family: 宋体;color: rgb(46, 46, 46);font-weight: bold;font-size: 13px;background: rgb(255, 255, 255)">1.&nbsp;</span>
                            <strong>
                                 您可在全球范围内非独家的、永久性的为非商业目的（指以个人为单位、非商业产品运作的方式，运用数字作品完成个人作品的展示，包括但不限于个人论文、个人作品、个人展览、用于向客户展示工作成果等）以合法形式使用您购买的数字作品，您在本协议项下获得的授权为不可分授权、不可转授权（无论是否因此获得收益），您不得将数字作品的任何部分或全部向任何第三方（包括自然人及具备或不具备法人主体资格的机构、组织）或为任何第三方的利益而复制、发行、销售、出租、通过信息网络进行传播、翻译、许诺销售、提供、许可、使用或以其他形式使用。
                            </strong>
                        </p>
                        <p class="content_p4" style="margin-left:21px">
                            <span style="font-family: 宋体;color: rgb(46, 46, 46);font-weight: bold;font-size: 13px;background: rgb(255, 255, 255)">2.&nbsp;</span>
                            <strong>
                                 您通过使用与我们有合作关系的第三方提供的文档设计服务所获取的数字作品的使用授权均来源自该第三方，该等数字作品的使用授权的限制均以您与该第三方的相关约定为准。
                            </strong>
                        </p>
                        <p class="content_p4" style="margin-left:21px">
                            <span style="font-family: 宋体;color: rgb(46, 46, 46);font-weight: bold;font-size: 13px;background: rgb(255, 255, 255)">3.&nbsp;</span>
                            <strong>
                                 您应在我们授权使用范围内合法使用通过本服务所获得的数字作品；在与我们有合作关系的第三方授权使用范围内合法使用通过该第三方提供文档设计服务所获取的数字作品；并保证在使用本款约定的任何数字作品时均不侵犯任何一方的合法权益；如您违反上述保证导致课之一（西安）科技有限公司或任何第三方被追究责任或遭受任何损失，您应为此承担全部法律责任。
                            </strong>
                        </p>
                        <!-- 免责声明及有限保证 -->
                        <p class="content_p5">
                            <span style="font-family: 宋体;font-weight: bold;font-size: 19px">（五）&nbsp;</span>
                            <strong><span style="font-family: 宋体;font-size: 19px"><span style="font-family:宋体">免责声明及有限保证</span></span></strong>
                        </p>
                        <p class="content_p4" style="margin-left:21px">
                            <span style="font-family: 宋体;color: rgb(46, 46, 46);font-weight: bold;font-size: 13px;background: rgb(255, 255, 255)">1.&nbsp;</span>
                            <strong>
                                 我们仅向您提供数字作品开放平台以便您得以通过素材库平台下载、购买、定制（如文档设计服务）数字作品，在法律允许的最大限度范围内，我们不对您通过上述形式获取的数字作品的合法性、不侵权性做任何口头、书面陈述的担保，或对此承担任何责任。我们不对您与任何第三方之间达成的或涉及的法定义务或约定义务承担任何责任。在法律允许的最大限度范围内，我们无义务参与因您从素材库平台下载、购买、定制数字作品引发的任何理赔、纠纷处理等活动。
                            </strong>
                        </p>
                        <p class="content_p4" style="margin-left:21px">
                            <span style="font-family: 宋体;color: rgb(46, 46, 46);font-weight: bold;font-size: 13px;background: rgb(255, 255, 255)">2.&nbsp;</span>
                            <strong>
                                 我们通过素材库平台提供一些实际系由第三方提供的产品或服务（包括文档设计以及后续可能新增推出的其他第三方提供的服务、产品（如有）），您知悉并认可该等产品、服务系由第三方独立提供，我们对于该等第三方提供的产品、服务的服务内容、服务质量、该第三方服务提供方的资质、信用以及服务能力等不承担任何审查或保证的义务以及责任，如您使用上述第三方提供的产品、服务产生纠纷或遭受损失，您仅能直接向该第三方服务提供方主张权利并由该第三方服务提供方独立并直接向您承担全部法律责任。
                            </strong>
                        </p>
                        <!-- 用户行为 -->
                        <p class="content_p5">
                            <span style="font-family: 宋体;font-weight: bold;font-size: 19px">（六）&nbsp;</span>
                            <strong><span style="font-family: 宋体;font-size: 19px"><span style="font-family:宋体">用户行为</span></span></strong>
                        </p>
                        <p class="content_p4" style="margin-left:21px">
                            <strong>
                                 我们通过素材库 - 版权方对其经由本服务（或本服务包含的任何子服务）上传、发布、存储、共享的内容（以下简称“信息服务内容”）自行承担全部责任，与我们无关。我们无法控制信息服务内容，因此不保证信息服务内容之正确性、合法性、安全性、完整性或质量。
                            </strong>
                        </p>
                        <!-- 法律管辖 -->
                        <p class="content_p5">
                            <span style="font-family: 宋体;font-weight: bold;font-size: 19px">（七）&nbsp;</span>
                            <strong><span style="font-family: 宋体;font-size: 19px"><span style="font-family:宋体">法律管辖</span></span></strong>
                        </p>
                        <p class="content_p4" style="margin-left:21px">
                            <span style="font-family: 宋体;font-size: 13px;">
                                1．本协议的订立、执行和解释及争议的解决均应适用中国大陆法律。
                            </span>
                        </p>
                        <p class="content_p4" style="margin-left:21px">
                            <span style="font-family: 宋体;font-size: 13px;">
                                2．如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，您和课之一（西安）科技有限公司一致同意交由课之一（西安）科技有限公司所在地有管辖权的法院管辖。
                            </span>
                        </p>
                        <!-- 其他 -->
                        <p class="content_p5">
                            <span style="font-family: 宋体;font-weight: bold;font-size: 19px">（八）&nbsp;</span>
                            <strong><span style="font-family: 宋体;font-size: 19px"><span style="font-family:宋体">其他</span></span></strong>
                        </p>
                        <p style="margin: 0 0 0 15px;text-align: justify;font-family: 'Times New Roman';font-size: 14px;white-space: normal;line-height: 21px">
                            <span style="font-family: 宋体;font-size: 13px">1.&nbsp;</span>
                            <span style="font-family: 宋体;line-height: 20px;font-size: 13px">
                                <span style="font-family:宋体">即便您没有点击同意本协议，下述行为也将视为您对本协议及课之一（西安）科技有限公司在线服务协议所有条款的认可：</span>
                            </span>
                        </p>
                        <p style="margin: 0 0 0 38px;text-align: justify;font-family: 'Times New Roman';font-size: 14px;white-space: normal;line-height: 21px">
                            <span style="font-family: 宋体;font-size: 13px">1.1.&nbsp;</span>
                            <span style="font-family: 宋体;line-height: 20px;font-size: 13px">
                                <span style="font-family:宋体">点击登录本服务；</span>
                            </span>
                        </p>
                        <p style="margin: 0 0 0 38px;text-align: justify;font-family: 'Times New Roman';font-size: 14px;white-space: normal;line-height: 21px">
                            <span style="font-family: 宋体;font-size: 13px">1.2. &nbsp;</span>
                            <span style="font-family: 宋体;line-height: 20px;font-size: 13px">
                                <span style="font-family:宋体">实际使用本服务；</span>
                            </span>
                        </p>
                        <p style="margin: 0 0 0 38px;text-align: justify;font-family: 'Times New Roman';font-size: 14px;white-space: normal;line-height: 21px">
                            <span style="font-family: 宋体;font-size: 13px">1.3. &nbsp;</span>
                            <span style="font-family: 宋体;line-height: 20px;font-size: 13px">
                                <span style="font-family:宋体">实际使用第三方连同本服务一并提供的任何产品或服务（如有）。</span>
                            </span>
                        </p>
                        <p style="margin: 0 0 0 15px;text-align: justify;font-family: 'Times New Roman';font-size: 14px;white-space: normal;line-height: 21px">
                            <span style="font-family: 宋体;font-size: 13px">2.&nbsp;</span>
                            <span style="font-family: 宋体;line-height: 20px;font-size: 13px">
                                <span style="font-family:宋体">
                                   如您使用的本服务是由与课之一（西安）科技有限公司有合作关系的第三方提供的，您在同意并遵守课之一（西安）科技有限公司在线服务系列协议的同时，还应同意并遵守该第三方的相关服务协议（如有），如第三方的相关服务协议（如有）条款与本服务协议条款约定存在抵触的，均以较严格的条款约定为准。
                                </span>
                            </span>
                        </p>
                        <p class="content_p1" style="height:30px">
                            <strong>
                                <span style="font-family: 宋体;font-size: 16px">&nbsp;</span>
                            </strong>
                        </p>
                        <p class="content_p1" style="height:30px">
                            <strong>
                                <span style="font-family: 宋体;font-size: 16px">&nbsp;</span>
                            </strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            
        }
    }
}
</script>
<style scoped>
@import "../../styles/Page/Contactus.css";
</style>